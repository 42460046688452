<template>
  <div>
    <div v-if="!requestDone">
      <form
        autocomplete="off"
        @submit.prevent="submit"
      >
        <div class="u-mb-5">
          <div class="c-form__group">
            <input
              id="email"
              v-model="form.email"
              type="email"
              class="c-form__control"
              name="email"
              placeholder="Adresse e-mail*"
              required
            />
          </div>
          <div
            v-if="errorMessage"
            class="is-invalid u-mt-1"
          >
            {{ errorMessage }}
          </div>
        </div>
        <div class="u-d-f u-jc-fe u-ai-c">
          <span
            class="link"
            @click="emit('openLoginModal')"
            >Retour à la connexion</span
          >
          <ButtonSimple
            type="submit"
            label="Réinitialiser le mot de passe"
          />
        </div>
      </form>
    </div>
    <div v-else>
      <p class="u-mb-4">
        Un email vient de vous être envoyé à l’adresse spécifiée vous permettant
        de réinitialiser votre mot de passe.
        <br />
        <br />
        <strong>Attention, ce lien n'est valide que 48h.</strong>
      </p>
      <div class="u-d-f u-jc-fe">
        <ButtonSimple
          type="button"
          label="Fermer"
          @click="vfm.closeAll()"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useVfm } from 'vue-final-modal'

const emit = defineEmits<{
  openLoginModal: []
  close: []
}>()

const vfm = useVfm()

const requestDone = ref(false)
const errorMessage = ref('')
const form = ref({
  email: '',
})

const submit = async () => {
  const formData = { ...form.value }

  const { data, error } = await useCustomFetch(API.POST__PASSWORD_TOKEN, {
    method: 'POST',
    body: formData,
    onResponse({ response }) {
      errorMessage.value = response._data.message
    },
  })

  const rawData = unref(data)
  if (error.value || !rawData) {
    return console.error(
      'from forgotten password modal',
      'There was a problem submitting email: ',
      error.value,
    )
  }

  requestDone.value = true
}
</script>

<style lang="scss" scoped>
.link {
  display: flex;
  align-items: center;
  margin-right: 2rem;
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    width: 0.5rem;
    height: 1rem;
    margin-right: 0.8rem;
    background-image: url('~/assets/icons/chevron.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: rotate(180deg);
  }
}

.is-invalid {
  color: #ea5151;
}
</style>
